<template>
  <div class="row">
   
    <div class="col-12">
      <div class="card-box mb-2">
        <!-- streetview -->
        <div id="googlePano" class="google"></div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
export default {
  name: "GoogleMapStreet",
  data() {
    const { currentRoute } = router
    return {
      center: { lat: Number, lng: Number },
      address: currentRoute.value.query.addr,
      reload: 0,
      google: {
        maps: {
          StreetViewPanorama: {},
          Map: {},
          MapTypeId: {},
        },
      },
    };
  },
  mounted() {
    this.loadGoogleMapsScript().then(() => {
      this.google = window.google;
      this.initializeMap();
    });
  },
  methods: {
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google) {
          return resolve(window.google);
        }
        const script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.VUE_APP_GOOGLE_KEY +
          "&callback=initMap&libraries=geometry";
        const head = document.querySelector("head");
        if (!head) return reject(new Error("head node is undefined"));
        head.appendChild(script);
        window.initMap = () => {
          resolve(window.google);
        };
        setTimeout(() => {
          if (!window.google) {
            reject(new Error("failed load google api"));
          }
        }, 5000);
      });
    },
    initializeMap() { 
      const panoContainer = this.$el.querySelector("#googlePano");
      const { StreetViewPanorama } = this.google.maps;
      const Geocoder = new this.google.maps.Geocoder();
      // map create
      let pano = new StreetViewPanorama(panoContainer, {
        pov: {
          heading: 0,
          pitch: 0,
        },
      });
      var _that=this;
      new Geocoder.geocode({ address: _that.address }, function (results, status) {
        if (status === "OK" && results[0]) {
          let locate = results[0].geometry.location;
          _that.center = {
            lat: Number(locate.lat()),
            lng: Number(locate.lng()),
          };

          // set locate
          
          pano.setPosition(_that.center);
        } else {
          console.log("failed load google api  : " + status);
          return;
        }
      });
    }
  },
  watch: {
    reload: function() {
      this.initializeMap()
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.google {
  height: 600px;
}
</style>
